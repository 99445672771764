import FetchWorker from "worker-loader!./fetch.worker";
export const FetchHandler = () => {
    const fetchWorker = new FetchWorker();
    let listeners = new Map();
    /// Handle all messages return from  our wasm worker
    fetchWorker.onmessage = (event) => {
        if (!listeners.has(event.data.name))
            return;
        listeners.get(event.data.name)(event.data.result);
    };
    return {
        load: () => {
            return new Promise((resolve, reject) => {
                /// Post to webworker
                fetchWorker.postMessage({
                    name: "load",
                });
                /// Add resolve to listeners as a clousre
                listeners.set("load", (res) => resolve(res));
            });
        },
    };
};
