import { FetchHandler } from "./fetch.handler";
console.log("HELLO WORLD");
FetchHandler()
    .load()
    .then((resp) => {
    console.log("Engine loaded", resp);
    const s = document.createElement("script");
    const url = URL.createObjectURL(resp);
    s.src = url;
    document.body.appendChild(s);
    // URL.revokeObjectURL(resp);
});
